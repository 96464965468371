@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter Semibold';
  font-style: normal;
  src: url('/src/assets/fonts/inter/Inter-SemiBold.ttf');
}
@layer base {
  :root {
    --color-warning: 255 211 92;
    --color-orange: 228 113 88;
    --color-purple-light: 91 32 188;
    --color-purple-dark: 52 12 117;
    --color-purple-disabled: 192 175 222;
    --color-gray-100: 248 248 248;
    --color-gray-200: 102 102 102;
    --color-gray-300: 51 51 51;
    --color-pink: 245 240 246;
    --color-success: 5 170 1;
    --color-danger: 209 16 16;
    --color-danger-light: 219 48 65;
    --color-danger-disabled: 255 212 212;
    --color-black: 0 0 0;
    --color-white: 255 255 255;
    --color-red-100: 254 226 226;
    --color-red-800: 153 27 27;
    --color-green-100: 220 252 231;
    --color-green-800: 22 101 52;
    --color-blue-100: 219 234 254;
    --color-blue-800: 30 64 175;
    --color-yellow-100: 254 249 195;
    --color-yellow-800: 133 77 14;
    --color-marine: 29 132 128;
    --color-brandblue: 22 115 193;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
